/*存放接口埋点*/

//统一模版函数处理
const defaultTrack = {
	getTrackType: function (resp) {
		let rp = resp?.data?.data || {}
		if (rp?.code == 200) {
			return "success"
		} else {
			return "error"
		}
	},
	getTrackValue: function (resp, params) {
		let reqParams = JSON.parse(resp.config.data || "{}")
		return {
			...params,
			value: {
				_pageName: location.href,
				_params: {uk: reqParams.serviceName, ins: reqParams.instances},
				_response: resp
			}
		}
	},
	getTrackType_0: function (resp) {
		let rp = resp?.data?.data?.data || null
		if (rp) {
			return "success"
		} else {
			return "error"
		}
	},
	getTrackValue_0: function (resp, params) {
		let reqParams = resp?.data?.data?.data || {}
		return {
			...params,
			value: {
				_pageName: location.href,
				_params: {user: reqParams.userName},
				_response: resp
			}
		}
	},
	getTrackType_1: function (resp) {
		let rp = resp?.data?.data || null
		if (rp) {
			return "success"
		} else {
			return "error"
		}
	},
	getTrackValue_1: function (resp, params) {
		let reqParams = resp?.data?.data || {}
		return {
			...params,
			value: {
				_pageName: location.href,
				_params: {user: reqParams.userName},
				_response: resp
			}
		}
	},
	getTrackType_2: function (resp) {
		let rp = resp?.data?.admin || null
		if (rp) {
			return "success"
		} else {
			return "error"
		}
	},
	getTrackValue_2: function (resp, params) {
		let reqParams = resp?.data || {}
		return {
			...params,
			value: {
				_pageName: location.href,
				_params: {user: reqParams.userName},
				_response: resp
			}
		}
	}
}

//具体埋点处理
export default {
	editInstancePorts: {
		getTrackType: defaultTrack.getTrackType,
		getTrackValue: defaultTrack.getTrackValue,
		success: {
			eventid: "14722",
			category: "insEvent",
			action: "instance-change-port-success",
			label: "实例变更端口成功",
			value: ""
		},
		error: {
			eventid: "14723",
			category: "insEvent",
			action: "instance-change-port-error",
			label: "实例变更端口失败",
			value: ""
		},
	},
	editState: {
		getTrackType: defaultTrack.getTrackType,
		getTrackValue: defaultTrack.getTrackValue,
		success: {
			eventid: "14708",
			category: "insEvent",
			action: "instance-change-state-success",
			label: "实例变更负载成功",
			value: ""
		},
		error: {
			eventid: "14709",
			category: "insEvent",
			action: "instance-change-state-error",
			label: "实例变更负载失败",
			value: ""
		},
	},
	getBalderUserInfo: {
		getTrackType: defaultTrack.getTrackType_0,
		getTrackValue: defaultTrack.getTrackValue_0,
		success: {
			eventid: "14731",
			category: "permissions",
			action: "get-balder-id-success",
			label: "获取用户巴德ID成功",
			value: ""
		},
		error: {
			eventid: "14732",
			category: "permissions",
			action: "get-balder-id-error",
			label: "获取用户巴德ID失败",
			value: ""
		},
	},
	getGlobalRole: {
		getTrackType: defaultTrack.getTrackType_2,
		getTrackValue: defaultTrack.getTrackValue_2,
		success: {
			eventid:"14733",
			category:"permissions",
			action:"get-balder-roles-success",
			label:"获取用户巴德角色成功",
			value:""
		},
		error: {
			eventid:"14734",
			category:"permissions",
			action:"get-balder-roles-error",
			label:"获取用户巴德角色失败",
			value:""
		},
	},
	getMyCollectAPP: {
		getTrackType: defaultTrack.getTrackType_1,
		getTrackValue: defaultTrack.getTrackValue_1,
		success: {
			eventid: "14955",
			category: "列表树",
			action: "read-interface-treelist-success",
			label: "列表树[我的收藏]",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
		error: {
			eventid: "14956",
			category: "列表树",
			action: "read-interface-treelist-error",
			label: "列表树[我的收藏]",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
	},
	getMyNodes: {
		getTrackType: defaultTrack.getTrackType_1,
		getTrackValue: defaultTrack.getTrackValue_1,
		success: {
			eventid: "14955",
			category: "列表树",
			action: "read-interface-treelist-success",
			label: "列表树[我的应用]",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
		error: {
			eventid: "14956",
			category: "列表树",
			action: "read-interface-treelist-error",
			label: "列表树[我的应用]",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
	},
	getAllNodes: {
		getTrackType: defaultTrack.getTrackType_1,
		getTrackValue: defaultTrack.getTrackValue_1,
		success: {
			eventid: "14955",
			category: "列表树",
			action: "read-interface-treelist-success",
			label: "列表树[所有应用]",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
		error: {
			eventid: "14956",
			category: "列表树",
			action: "read-interface-treelist-error",
			label: "列表树[所有应用]",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
	},
	restartInstance: {
		getTrackType: defaultTrack.getTrackType_1,
		getTrackValue: defaultTrack.getTrackValue_1,
		success: {
			eventid: "14716",
			category: "insEvent",
			action: "instance-restart-task-success",
			label: "实例重启下发任务成功",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
		error: {
			eventid: "14717",
			category: "insEvent",
			action: "instance-restart-task-error",
			label: "实例重启下发任务失败",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
	},
	startInstance: {
		getTrackType: defaultTrack.getTrackType_1,
		getTrackValue: defaultTrack.getTrackValue_1,
		success: {
			eventid: "14712",
			category: "insEvent",
			action: "instance-start-success",
			label: "实例启动成功",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
		error: {
			eventid: "14713",
			category: "insEvent",
			action: "instance-start-error",
			label: "实例启动失败",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
	},
	stopInstance: {
		getTrackType: defaultTrack.getTrackType_1,
		getTrackValue: defaultTrack.getTrackValue_1,
		success: {
			eventid:"14714",
			category:"insEvent",
			action:"instance-stop-task-success",
			label:"实例停止下发任务成功",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
		error: {
			eventid:"14715",
			category:"insEvent",
			action:"instance-stop-task-error",
			label:"实例停止下发任务失败",
			//value:{_pageName:location.href,_params:{retry:num,}}
		},
	}
	
}