<template>
    <div class="home">
        <Sign :config="signConfig"></Sign>
    </div>
</template>

<script type="text/javascript">

import Sign from '../components/sign'
export default {
    name: 'Home',
    components: {
        Sign
    },
    data(){
        return {
            signConfig:{
                type:"message",
                content: `一个人未来能去哪，不是靠想象，<br/>而是靠今天做了什么，<br/>做得怎么样。<br/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;------<a style="text-decoration: none;color:inherit" href="/list">胡言乱语</a>`,
            }
        }
    }
}
</script>

<style scoped lang="less">
.home {
    overflow: auto;
	background: #ececec;
	transform: perspective(400px);
	//background: linear-gradient(to right, rgba(34, 193, 17, 0.5) 0%, rgba(83, 203, 241, 0.7) 40%, rgb(224, 116, 74) 100%); /* W3C */
}
</style>
