<template>
        <marquee v-if="notice&&notice!=0" class="notice">{{notice}}</marquee>
</template>

<script>
import http from "../module/http/http"
export default {
    name: "notice",
    data(){
        return {
            notice:""
        }
    },
    mounted() {
        //this.init()
    },
    methods:{
        init(){
            this.getNotice()
            setInterval(()=>{
                this.getNotice()
            },60000)
        },
        getNotice(){
            http.get(`/api/getPosts?request_url=/repos/ooszone/posts/contents/notice.md`,{
            }).then((res)=>{
                if(res&&res.data){
                    let obj = {
                        name:res.data.name,
                        content:decodeURIComponent(escape(window.atob(res.data.content)))
                    }
                    this.notice = obj.content;
                }
            }).catch((err)=>{
                this.err = err
            })
        }
    }

}
</script>

<style scoped>
    .notice{
        background-color: #c18f67;
        color: #800707;
        font-size:12px;
        padding:5px 0;
        height: 20px;
        line-height: 20px;
        border-top:1px solid #333;
        min-height: 20px;
    }
</style>