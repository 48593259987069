/*队列缓存*/
export default {
	async getDeployQueue(){
		return (await $cache.getLocalCache("deploying_uk_list_queue"))?.value || [];
	},
	async updateDeployQueue(ukList){//更新部署中UK队列
		await $cache.updateLocalCache("deploying_uk_list_queue",ukList,15*60000,Date.now())//15min
	},
	async addUKToDeployQueue(item){
		let ukList = (await $cache.getLocalCache("deploying_uk_list_queue"))?.value || [];
		for(let i in ukList){
			if(ukList[i].uk==item.uk){
				return;
			}
		}
		ukList.push(item)
		await this.updateDeployQueue(ukList)
	},
	async deleteUKFromDeployQueue(item){
		let ukList = (await $cache.getLocalCache("deploying_uk_list_queue"))?.value || [];
		for(let i in ukList){
			if(ukList[i].uk==item.uk){
				ukList.splice(i,1);
			}
		}
		await this.updateDeployQueue(ukList)
	},
}