<template>
    <main>
        <circle-load-progress>
            <circle-single slot="right"></circle-single>
        </circle-load-progress>
    </main>
</template>
<script>
export default {
    name:"circle-rings",
}
</script>
<style scoped>

</style>