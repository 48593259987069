<template>
	<div id="app" class="flexH">
		<section class="flex1" style="position: relative" v-track="`header`" @click="testPoint">
			<Menus v-track="`pai`" :title="'π='+Math.PI"></Menus>
		</section>
		<section class="flex1">
			<notice></notice>
		</section>
		<section class="flex99" style="background:#f9f9f9;overflow-y: auto">
			<router-view/>
		</section>
		<section class="flex1">
			<a v-track="`href`" @click="testHref('a','b')" style="font-size:12px;color:orange;text-decoration:none;position: absolute;bottom:10px;right:50px;" target="_blank" href="http://www.beian.miit.gov.cn"></a>
		</section>
	</div>
</template>

<script type="text/javascript">
import Menus from './components/menus'
import Notice from "@/components/notice";

export default {
	name: 'App',
	components: {
		Notice,
		Menus,
	},
	data() {
		return {}
	},
	created() {
		this.init()
	},
	mounted() {
		//this.init()
	},
	methods: {
		async init() {
			let resp = await $api.getUserInfo({a: 1, b: 2})
			console.log(resp)
			$cache.addMemoryCache("init",{
				initComplete:true
			})
		},
		testPoint(e) {
			console.log(e)
			$cache.removeMemoryCache("init")
		},
		testHref(a, b) {
			console.log(a, b)
		},
	}
}
</script>
<style scoped lang="less">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: -webkit-flex;flex-direction: column;
	box-sizing: border-box;
	overflow: hidden;
}
</style>

