// import extend from "@/module/extend/extend";
// import http from "@/module/http/http";

//import Tool from "@/public/Tool";


// let cancel;
// http.get('/user/12345', {
// 	cancelToken: new CancelToken(function executor(c) {
// 		cancel = c;
// 	})
// });
// cancel();
/**
 * 统一管理接口model，统一管理是否启用接口缓存，统一管理接口是否使用mockdata,比如product强制使用缓存,不使用mockdata
 */
// example ：
// getExample: params => http.get(`/${apiVersion}/jean/getBuildHistory${extend.jsonToQueryString(params)}`,config:{
// 此参数可选，默认值参考 /module/http/http.js创建和拦截器的默认参数
// })
// example ：
// getExample: params => http.post(`/${apiVersion}/jean/getBuildHistory`,params,config:{
// 此参数可选，默认值参考 /module/http/http.js创建和拦截器的默认参数
// })

export default {

}
