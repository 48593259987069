<template>
	<main ref="iframLoadHtml">
		<iframe ref="iframe-container" @loadstart="loadIframe" onhashchange="loadIframe" style="border:1px solid transparent;position:absolute;left:0;top:0;width:100%;height:100%" :src="url">
		</iframe>
	</main>
</template>
<script>
import http from "../../module/http/http"

export default {
	name: "htmlLoad",
	props: ["url"],
	methods: {
		loadHtml(url) {
			http.get(url, {
				accept: 'text/html, text/plain'
			}).then((res) => {
				let iframe = document.createElement("iframe")
				iframe.style = "border:1px solid transparnet;position:absolute;left:0;top:0;width:100%;height:100%"
				iframe.srcdoc = res.data;
				this.$refs["iframLoadHtml"].innerHTML = ""
				this.$refs["iframLoadHtml"].appendChild(iframe)
			}).catch((err) => {
				console.log(err)
			})
		},
		parseDom() {
			let iframe = this.$refs["iframe-container"]
			let origin = location.origin;
			let _hash = location.hash.split("#")[1]
			let hash = iframe.src.split(origin)[1]
			if (encodeURIComponent(hash) != _hash) {
				// if (hash.indexOf("gitbook/") != -1) {
				// 	location.hash = "/gitbook/" + encodeURIComponent(hash)
				// }
			}
		},
		loadIframe(e) {
			this.parseDom(e)
		}
	},
	mounted() {
		//this.loadHtml(this.url)
		this.parseDom()
	}
}
</script>
