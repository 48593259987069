import Vue from 'vue'
import Vuex from 'vuex'
import permission from "@/store/module/permission";
import cache from "@/store/module/cache";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		permission,
		cache
	},
	getStore: state => {
		return state
	}
})
