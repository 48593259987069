import Vue from 'vue' //引入vue框架
import App from './App.vue' //首页
import router from './router' //引入路由
import store from './store' //引入全局
import componentsInstall from './install' //自定义标签组件，可封装为公共组件，不受项目限制，可视为自定义UI库
import  "@/module/module" //引入自定义扩展模块，具体请参考对应的文件夹
import "@/module/icon/icon.css" //通用icon

//TODO 全局变量不支持记得在.eslintrc.js里配置
Vue.config.errorHandler = (err, vm, info)=>{//vue钩子错误监控
	$monitor.captureException({
		err,vm,info
	})
}
Vue.config.warnHandler = (err, vm, info)=>{//vue钩子警告监控
	$monitor.captureException({
		err,vm,info
	})
}


Vue.use(componentsInstall)//全局组件注册

//实例化
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
